<template>
  <div class="pricing-container mx-auto">
    <div
      v-if="hasStepGuide"
      data-testid="btn-back-to-billing-detail"
      class="d-flex align-items-center text-placeholder my-2 cursor-pointer"
      @click="$router.push('billing')"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        style="margin-right: 8px"
      />
      {{ $t('pricingPage.textBackBilling') }}
    </div>
    <!-- purchase success page -->
    <div
      v-if="showSuccessPage"
    >
      <HeaderPricing
        :show-checkout-page.sync="showCheckoutPage"
        :show-success-page.sync="showSuccessPage"
      />
      <div class="mx-auto success-page mb-1">
        <purchase-success
          :show-success-page.sync="showSuccessPage"
          :checkout-data="checkoutData"
          :is-monthly="isMonthly"
        />
      </div>
    </div>
    <!-- /end purchase success page -->

    <!-- plan list page -->
    <div v-else-if="!showCheckoutPage">
      <div
        v-if="!loading"
        class="d-flex flex-column align-items-center mb-3"
      >
        <h1 class="text-center font-32 mb-1 font-medium">
          {{ title }}
        </h1>
        <p
          style="max-width: 500px; line-height: 27px"
          data-testid="text-center-trial-day"
          class="text-center"
        >
          {{ description }}
        </p>
      </div>

      <div v-if="!loading">
        <div
          v-if="listPlan.length === 0"
          class="text-center font-medium"
        >
          {{ $t('pricingPage.textNoPlan') }}
        </div>
        <pricing-item
          v-else
          :plans-status="plansStatus"
          :plans="listPlan"
          :valid-pricing="validPricing"
          :fetch-plan-loading="loading"
          @choose-plan="gotoCheckoutPage"
        />
      </div>
    </div>

    <!-- end plan list page -->

    <!-- plan checkout page -->
    <div v-else>
      <HeaderPricing
        v-if="!reconfirmPlanStatus"
        :show-checkout-page.sync="showCheckoutPage"
      />
      <billing-checkout
        :show-checkout-page.sync="showCheckoutPage"
        :show-success-page.sync="showSuccessPage"
        :checkout-data="checkoutData"
        :is-monthly="isMonthly"
        @choose-plan="gotoCheckoutPage"
      />
    </div>
    <!--/ end plan checkout page -->
  </div>
</template>
<script>
/* eslint-disable global-require */
import { createNamespacedHelpers } from 'vuex'
import { getResponse } from '@/plugins/store'
import axios from '@axios'
import partnerMixin from '@/mixins/partnerMixin'
import { toastification } from '@core/mixins/toast'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import _get from 'lodash/get'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import PricingItem from './components/PricingItem.vue'
import BillingCheckout from './components/BillingCheckout.vue'
import PurchaseSuccess from './components/PurchaseSuccess.vue'
import HeaderPricing from './components/HeaderPricing.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('billing')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    HeaderPricing,
    BillingCheckout,
    PricingItem,
    PurchaseSuccess,
  },

  mixins: [partnerMixin, toastification, billingMixin, envMixin, stepGuideMixin],

  data() {
    return {
      isMonthly: true,
      showCheckoutPage: false,
      showSuccessPage: false,
      checkoutData: {},
      validPricing: [],
    }
  },
  computed: {
    ...mapGetters(['listPlan', 'loading', 'error', 'subscriptionStatus']),
    ...mapGettersAuth(['user', 'plansStatus']),

    title() {
      return this.plansStatus.outOfTrialAndNeedPlan && this.trialMode ? this.$t('pricingPage.titleTrialHasEnded') : this.$t('pricingPage.titleOneToolForYour')
    },

    description() {
      if (!this.trialMode) {
        return this.$t('pricingPage.subNotTrial', { companyName: this.companyName })
      }
      if (this.plansStatus.outOfTrialAndNeedPlan && this.trialMode) {
        return this.$t('pricingPage.subWeWillSave', { trialDay: this.trialDay })
      }
      return this.$t('pricingPage.subTryPlatformForDay', { companyName: this.companyName, trialDay: this.trialDay })
    },

    trialDay() {
      return this.user?.data?.plans?.subscription?.trial_days || 0
    },

    paymentIntentStatus() {
      return _get(this.subscriptionStatus, ['latestInvoice', 'paymentIntent', 'status'], '')
    },

    reconfirmPlanStatus() {
      const type = _get(this.subscriptionStatus, ['status'], '')
      return !!(type === 'incomplete' || type === 'incomplete_expired' || type === 'past_due')
        && (this.paymentIntentStatus === 'requires_confirmation'
          || this.paymentIntentStatus === 'requires_action'
          || this.paymentIntentStatus === 'requires_payment_method')
    },

    getPendingPlan() {
      const previousPlanName = this.subscriptionStatus?.plans
      return this.listPlan.find(plan => plan.nickname === previousPlanName)
    },

  },

  async created() {
    await this.getPricing()
    await this.getSubscriptionStatus()
    await this.checkHasContinuePlan()
  },

  methods: {
    ...mapActions(['getPricing', 'getSubscriptionStatus']),
    ...mapActionsAuth(['getAccountInfo']),

    async checkHasContinuePlan() {
      if (this.reconfirmPlanStatus) {
        const data = {
          isContinuePurchase: true,
          planData: await this.getPendingPlan,
        }
        this.showCheckoutPage = true
        await this.getStripeInvoice(data)
      }
    },

    async gotoCheckoutPage(data) {
      if (data.isContinuePurchase) {
        if (this.previousPlanName === data.planData?.nickname) {
          this.showCheckoutPage = true
          await this.getStripeInvoice(data)
        } else {
          this.toastFailure(`You need to continue purchase ${this.previousPlanName} plan before.`)
        }
      } else {
        this.showCheckoutPage = true
        await this.getStripeInvoice(data)
      }
    },

    async getStripeInvoice(plan) {
      try {
        this.checkoutData = {
          getInvoiceLoading: true,
        }
        const { data } = await axios.get(`/api/stripe-payment/subscriptions/register/preview-invoice?priceId=${plan?.planData?.id}`)
        const result = getResponse(data)
        this.checkoutData = {
          ...plan,
          stripeInvoice: result,
          getInvoiceLoading: false,
        }
      } catch (e) {
        this.checkoutData = {
          getInvoiceLoading: false,
        }
        this.toastFailure(e.data.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>

  .pricing-container{
    max-width: 1200px;
  }

  .pricing-card{
    min-height: 380px;
  }

  .time-toggle{
    margin-left: 15px;
    margin-right: 10px;
  }

  .success-page{
    max-width: 380px;
  }

  .trial-note{
    width: 388px;
    padding: 0 20px;
    background-color: rgba(61, 64, 91, 0.03);
    border-radius: 50px;
  }

  .horizontal-divide{
    width: 1px;
    height:40px;
    background: rgba(22, 33, 62, 0.1);
  }

</style>
