<template>
  <div
    id="add-card-form"
    data-testid="add-card-form"
    class="credit-form bg-white px-1"
  >
    <b-collapse
      v-model="showAddNewCard"
      class="py-2 px-1"
    >
      <b-row>
        <b-col cols="12">
          <label> Email </label>
          <b-form-input
            v-model="cardData.email"
            id="card-email"
            data-testid="card-email"
            :placeholder="$t('payment.creditCard.placeholderEmailCard')"
            class="input-custom d-inline-block"
            :class="{ 'card-error': cardEmailError && showErrorEmail }"
            @blur="handleOutFocus(type = 'email')"
            @input="validateEmail"
          />
          <div
            v-if="cardEmailError && showErrorEmail"
            id="error-card-email"
            data-testid="error-card-email"
            class="text-caption card-error"
          >
            {{ cardEmailError }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="12">
          <label class="mb-0"> {{ $t('payment.creditCard.labelCardNumber') }} </label>
          <div
            id="card-number"
            data-testid="card-number"
            class="input-value"
            :class="{ 'card-error': cardNumberError }"
          />
          <div
            v-if="cardNumberError"
            id="error-card-number"
            data-testid="error-card-number"
            class="text-caption card-error"
          >
            {{ cardNumberError }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="6">
          <label class="mb-0"> {{ $t('payment.creditCard.labelExpired') }} </label>
          <div
            id="card-expiry"
            data-testid="card-expiry"
            class="input-value"
            :class="{ 'card-error': cardExpiryError }"
          />
          <div
            v-if="cardExpiryError"
            id="error-card-expiry"
            data-testid="error-card-expiry"
            class="text-caption card-error"
          >
            {{ cardExpiryError }}
          </div>
        </b-col>

        <b-col cols="6">
          <label class="mb-0"> CVC </label>
          <div
            id="card-cvc"
            data-testid="card-cvc"
            class="input-value"
            :class="{ 'card-error': cardCvcError }"
          />
          <div
            v-if="cardCvcError"
            id="error-card-cvc"
            data-testid="error-card-cvc"
            class="text-caption card-error"
          >
            {{ cardCvcError }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="12">
          <label> {{ $t('payment.creditCard.labelNameOnCard') }} </label>
          <b-form-input
            v-model="cardData.name"
            id="card-name"
            data-testid="card-name"
            :placeholder="$t('payment.creditCard.labelNameOnCard')"
            class="input-custom d-inline-block"
            :class="{ 'card-error': cardNameError && showErrorName }"
            @input="validateName"
            @blur="handleOutFocus(type = 'name')"
          />
          <div
            v-if="cardNameError && showErrorName"
            id="error-card-name"
            data-testid="error-card-name"
            class="text-caption card-error"
          >
            {{ cardNameError }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <btn-loading
            data-testid="btn-add-card"
            class="width-button"
            variant-convert="btn-submit"
            pill
            :loading="saveCardLoading"
            @click="saveCard"
          >
            {{ $t('addFundPage.creditCardPayment.textAddCard') }}
          </btn-loading>
        </b-col>
      </b-row>

      <div
        v-if="saveCardError"
        id="error-message-stripe"
        data-testid="error-message-stripe"
        class="error-message-stripe"
      >
        <div class="d-flex align-items-start">
          <div class="d-flex align-items-center">
            <img
              id="ic-error-stripe"
              data-testid="ic-error-stripe"
              style="margin-right: 8px"
              alt="alert"
              :src="require('@/assets/images/common/ic-warning-red.svg')"
            >
            <p
              id="text-error-stripe"
              data-testid="text-error-stripe"
              class="m-0 font-weight-600"
            >
              {{ $t('common.textError') }}:
            </p>
          </div>
          <p
            id="content-error-stripe"
            data-testid="content-error-stripe"
            class="content-error"
          >
            {{ saveCardError }}
          </p>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import {
  BFormInput,
  BRow,
  BCol,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCollapse,
    BtnLoading,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    saveCardError: {
      type: String,
      required: false,
      default: '',
    },
    cardData: {
      type: Object,
      required: false,
      default: () => {},
    },
    cardNumberError: {
      type: String,
      required: false,
      default: '',
    },
    cardExpiryError: {
      type: String,
      required: false,
      default: '',
    },
    cardCvcError: {
      type: String,
      required: false,
      default: '',
    },
    cardNameError: {
      type: String,
      required: false,
      default: '',
    },
    cardEmailError: {
      type: String,
      required: false,
      default: '',
    },
    saveCardLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddNewCard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      showErrorEmail: true,
      showErrorName: true,
    }
  },

  computed: {
    stripeElements() {
      return this.$stripe.elements()
    },
  },

  // watch: {
  //   cardData: {
  //     handler(val) {
  //       this.$emit('change-email', val.email)
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },

  methods: {
    saveCard() {
      this.$emit('save-card')
    },

    validateEmail() {
      this.cardData.email = this.cardData.email.trim()
      this.$emit('change-email', this.cardData.email)
      this.showErrorEmail = false
    },

    validateName() {
      this.showErrorName = false
      this.$emit('change-name', this.cardData.name.trim())
    },

    handleOutFocus(type) {
      if (type === 'email') {
        this.showErrorEmail = true
      } else if (type === 'name') {
        this.showErrorName = true
      }
    },
  },
}
</script>
<style lang="scss">
.card-element {
  label {
    font-size: 0.9rem;
    color: #000000de;
  }
}
#card-number,
#card-expiry,
#card-cvc {
  background: white;
  border: 1px solid #e0e0e5;
  border-radius: 0.357rem;
}

.input-value {
  margin-top: 4px !important;
  padding: 2px 10px !important;
}

.card-error {
  border-color: #e11c4e !important;
  color: #e11c4e !important;
  font-size: 0.825rem;
  margin-top: 3px;
}

.require {
  color: #e11c4e;
}

.img-curved {
  position: absolute;
  top: 64px;
  left: 81px;
}

.width-button {
  @media(max-width: 767px) {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
.input-custom {
  height: 50px;
  background: white;
  border: 1px solid #e0e0e5;
  border-radius: 0.357rem;
  padding: 2px 10px !important;
  font-size: 17px;
  font-weight: 300;
  font-family: sans-serif;
}

.form-control:focus,
.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #e0e0e5 !important;
  box-shadow: none !important;
}

.error-message-stripe{
  margin: 24px 0 0;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}
</style>
