/* eslint-disable */
import {createNamespacedHelpers} from 'vuex'
import _get from 'lodash/get'
import {toastification} from '@core/mixins/toast'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import { PAYMENT_METHOD, REGULAR_PAYMENT_SUPPORT } from '@/constants'
import paymentMixin from '@/mixins/paymentMixin'

const {mapGetters, mapActions} = createNamespacedHelpers('auth')
const {mapGetters: mapGettersBilling, mapActions: mapActionsBilling} = createNamespacedHelpers('billing')

export default {
  props: {
    plans: {
      type: Array,
      default: () => [],
    },
    validPricing: {
      type: Array,
      default: () => [],
    },

    fetchPlanLoading: {
      type: Boolean,
      default: false,
    }
  },

  mixins: [numberFormatMixin, toastification, billingMixin, envMixin, paymentMixin],

  data() {
    return {
      regularPlanSelected: {
        name: '',
        value: null
      },
    }
  },

  computed: {
    ...mapGetters(['user', 'plansStatus']),
    ...mapGettersBilling(['subscriptionStatus']),

    // Essentially using the white agency package instead of the enterprise package, this condition shows the information of the White package into an Enterprise package
    isNotEnterprise() {
      return !!this.isGoodvibesPlatform
    },

    latestInvoice() {
      return _get(this.subscriptionStatus, ['latestInvoice', 'paymentIntent'], {})
    },

    paymentIntentStatus() {
      return _get(this.subscriptionStatus, ['latestInvoice', 'paymentIntent', 'status'], '')
    },

    regularPlanFilter() {
      return this.plans.filter(plan => plan.nickname !== 'enterprise').sort((a, b) => a.amount - b.amount)
    },

    enterprisePlanFilter() {
      return this.plans.find(plan => plan.nickname === 'enterprise')
    },

    getCurrentRegularPlan() {
      return this.regularPlanFilter.find(plan => plan.nickname === this.regularPlanSelected.value)
    },

    getCurrentMemberPlan(){
      return this.plans && this.plans[0] || {}
    },

    regularPlanBenefits() {
      return _get(this.getCurrentRegularPlan, ['detail', 'planBenefits'], [])
    },

    enterPrisePlanBenefits() {
      return _get(this.enterprisePlanFilter, ['detail', 'planBenefits'], [])
    },

    regularPlanAmount() {
      return _get(this.getCurrentRegularPlan, ['amount'], 0)
    },

    enterprisePlanAmount() {
      return _get(this.enterprisePlanFilter, ['amount'], 0)
    },

    regularOptions() {
      return this.regularPlanFilter.map(plan => ({
        name: this.getMaxAds(plan.nickname),
        value: plan.nickname,
      }))
    },

    amount() {
      return value => this.currencyFormat(value / 100)
    },

    isTrialing() {
      const {isTrialing} = this.plansStatus
      return isTrialing
    },

    reconfirmPlanStatus() {
      const type = _get(this.subscriptionStatus, ['status'], '')
      return !!(type === 'incomplete' || type === 'incomplete_expired' || type === 'past_due')
        && (this.paymentIntentStatus === 'requires_confirmation'
          || this.paymentIntentStatus === 'requires_action'
          || this.paymentIntentStatus === 'requires_payment_method')
    },

    hasPreviousPlan() {
      if (this.reconfirmPlanStatus) { // reconfirm incomplete previous subscription
        if (this.latestInvoice?.paymentMethod) {
          return true
        }
      }

      return false
    },

    btnText() {
      return this.$t('billingPage.banner.textUpgrade')
    },

    btnEnterpriseText(){
      return this.$t('billingPage.banner.textUpgrade')
    },

    totalSpent() {
      return this.user?.data?.totalSpent || 0
    },
  },

  watch: {
    enterprisePlanFilter: {
      handler() {
          if(this.regularPlanFilter && this.regularPlanFilter[0])
          this.setDefaultPlan(this.regularPlanFilter[0].nickname)
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    ...mapActions(['getAccountInfo']),
    ...mapActionsBilling(['getPricing', 'getSubscriptionStatus']),

    setDefaultPlan(type) {
      return this.regularPlanSelected = {
        name: this.getMaxAds(type),
        value: type,
      }
    },

    getMaxAds(key) {
      const planObj = this.regularPlanFilter.find(item => item.nickname === key)
      if (planObj) {
        const maxAdsAccount = _get(planObj, ['detail', 'maxAdsAccount'], 0)
        const amount = _get(planObj, ['amount'], 0)

        return `${maxAdsAccount} Ad Account - ${this.amount(amount)}`
      }

      return `--`
    },

    goToCheckoutPage(type) {
        if (type === 'member') {
          this.$emit('choose-plan', {planData: this.getCurrentMemberPlan, isContinuePurchase: this.hasPreviousPlan})
        }else if (type === 'regular') {
          this.$emit('choose-plan', {planData: this.getCurrentRegularPlan, isContinuePurchase: this.hasPreviousPlan})
        }else if (type === 'contact') {
          this.goToWhatsApp()
        } else {
          this.$emit('choose-plan', {planData: this.enterprisePlanFilter, isContinuePurchase: this.hasPreviousPlan})
        }
      },
  },
}
