<template>
  <div>
    <div class="header px-2 pb-1 text-center">
      <img
        data-testid="img-purchase-success"
        alt="success"
        :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
      >
      <h3
        data-testid="title-purchase-success"
        class="my-1"
      >
        {{ $t('pricingPage.purchaseSuccessView.titleView') }}
      </h3>
      <p>{{ $t('pricingPage.purchaseSuccessView.contentThanksForPurchase') }}
        <span
          data-testid="url-billing"
          class="underline cursor-pointer text-purple"
          @click="goToBillingPage"
        >
          {{ $t('pricingPage.purchaseSuccessView.textBillingSubscription') }}
        </span>
        {{ $t('pricingPage.purchaseSuccessView.textToViewDetail') }}
      </p>
    </div>
    <div
      data-testid="summary-purchase-success"
      class="summary p-2"
    >
      <h3>{{ $t('addFundPage.creditCardPayment.textSummary') }}</h3>
      <p>
        {{ $t('pricingPage.purchaseSuccessView.textPaymentInformationDetails') }}
      </p>
      <hr class="hr-custom">
      <b-spinner
        v-if="getLoading"
        variant="secondary"
        small
      />
      <div v-else>
        <div v-if="upcomingPrice">
          <div class="d-flex align-items-center justify-content-between mb-1">
            <div class="font-14 text-placeholder">
              {{ $t('pricingPage.purchaseSuccessView.textPlanPackage') }}:
            </div>
            <strong data-testid="purchase-plan-name">
              {{ name }}
            </strong>
          </div>
          <div
            data-testid="purchase-base-price"
            class="d-flex align-items-center justify-content-between mb-1"
          >
            <div class="font-14 text-placeholder">
              {{ $t('pricingPage.purchaseSuccessView.textPrice') }}:
            </div>
            {{ basePrice }}
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-14 text-placeholder">
              {{ $t('pricingPage.purchaseSuccessView.textPlanCycle') }}:
            </div>
            <div>
              {{ cycleTimeTxt }}
            </div>
          </div>
        </div>
        <hr class="hr-custom">
        <div v-if="currentPrice && upcomingPrice">
          <div>
            <div class="d-flex align-items-center justify-content-between mb-1 font-medium font-14">
              {{ descriptionUpcomingPrice }}
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="font-14 text-placeholder">
                {{ $t('balance.textAmount') }}:
              </div>
              <div data-testid="purchase-amount-up-coming-price">
                {{ amountUpcomingPrice }}
              </div>
            </div>
          </div>
          <hr class="hr-custom">
          <div>
            <div class="d-flex align-items-center justify-content-between mb-1 font-medium font-14">
              {{ descriptionCurrentPrice }}
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="font-14 text-placeholder">
                {{ $t('balance.textAmount') }}:
              </div>
              <div data-testid="purchase-amount-current-price">
                {{ amountCurrentPrice }}
              </div>
            </div>
          </div>
          <hr class="hr-custom">
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1">
          <div class="font-14 text-placeholder">
            {{ $t('pricingPage.purchaseSuccessView.textTotal') }}:
          </div>
          <div class="d-flex align-items-center">
            <h3
              data-testid="purchase-total-price"
              class="font-medium"
            >
              {{ total }}
            </h3>
            /
            <span class="font-14 font-weight-normal">{{ cycleTimeTxt }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="action mt-2 text-center">
      <btn-loading
        variant-convert="btn-submit"
        pill
        data-testid="btn-add-fund-to-balance"
        @click="$router.push(isStepCreateAdAccount ? '/my-ads-account' : '/payment/add-fund')"
      >
        {{ btnBackTo }}
      </btn-loading>
    </div>
    <p
      v-if="isOnlyEcomdyPlatform"
      class="text-center mt-1"
    >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
      <span
        data-testid="btn-billing-invoice"
        class="cursor-pointer text-purple underline"
        @click="goToBillingPage"
      >
        {{ $t('pricingPage.purchaseSuccessView.textHere') }}
      </span>
    </p>
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import paymentMixin from '@/mixins/paymentMixin'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'

export default {
  components: {
    BtnLoading,
    BSpinner,
  },
  mixins: [numberFormatMixin, paymentMixin, stepGuideMixin, envMixin, generalConfigsMixin],
  props: {
    checkoutData: {
      type: Object,
      required: true,
      default: () => {},
    },
    isMonthly: {
      type: Boolean,
      required: true,
      default: true,
    },
    showSuccessPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCheckoutPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {

    getLoading() {
      return _get(this.checkoutData, ['getInvoiceLoading'])
    },

    btnBackTo() {
      if (this.isStepAddFund && this.isSetupCostModel) {
        return this.$t('pricingPage.purchaseSuccessView.btnSetupCost')
      } if (this.isStepCreateAdAccount) {
        return this.$t('pricingPage.purchaseSuccessView.btnCreateAdAccount')
      }
      return this.$t('pricingPage.purchaseSuccessView.btnAddFundToBalance')
    },

    total() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'total'], 0) / 100)
    },

    currentPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'currentPrice'], null)
    },

    amountCurrentPrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'currentPrice', 'amount'], 0) / 100)
    },

    descriptionCurrentPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'currentPrice', 'description'], null)
    },

    upcomingPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice'], null)
    },

    amountUpcomingPrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'amount'], 0) / 100)
    },

    descriptionUpcomingPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'description'], null)
    },

    cycleTimeTxt() {
      return this.isMonthly ? this.$t('partnerView.textMonthly') : this.$t('partnerView.textYearly')
    },

    basePrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'plan', 'amount'], 0) / 100)
    },

    name() {
      const nickName = _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'plan', 'nickname'])
      return nickName
    },
  },

  methods: {
    goToBillingPage() {
      if (this.isTikTokAppCenter) {
        const idToken = localStorage.getItem('id_token')
        window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
      } else {
        this.$router.push('/billing')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .hr-custom{
    border-top: 1px solid #EEEEEE;
  }

  .summary{
    border-radius: 16px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }
</style>
