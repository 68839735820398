import { createNamespacedHelpers } from 'vuex'
// import { PROFILE_STATUS } from '@/constants'
// import adsAccount from '@/mixins/adsAccount'
// import billingMixin from '@/mixins/billingMixin'
import _isEmpty from 'lodash/isEmpty'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
export default {
  data() {
    return {}
  },

  mixins: [],

  computed: {
    ...mapGettersAuth(['user']),

    isPartner() {
      return this.user?.data?.partnership?.status === 'active'
    },

    isMember() {
      return !_isEmpty(this.user?.data?.belongToPartner)
    },

    isPartnerViewMode() {
      return localStorage.getItem('partnerView')
    },

    hasAcl() {
      return this.user?.data?.roles?.find(role => role === 'VERIFY_USER_INFORMATION')
    },

    hasFormWhiteList() {
      return this.user?.data?.partnership?.formWhitelist || this.user?.data?.belongToPartner?.partnerId?.partnership?.formWhitelist
    },
  },
}
