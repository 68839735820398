<template>
  <b-row class="mx-2 card-item d-flex align-items-center">
    <b-col
      cols="4"
      md="3"
      class="pl-0 d-flex align-items-center"
    >
      <b-icon-record-circle
        v-if="isActive"
        data-testid="card-default"
        class="icon-color mr-1"
        font-scale="1.25"
      />
      <b-icon-circle
        v-else
        :data-testid="`card-not-default-${index}`"
        class="mr-1 cursor-pointer"
        style="color: #EEEEEE"
        font-scale="1.25"
        @click="chooseCard"
      />
      <img
        :src="getCreditCardLogo(card.brand)"
        alt="credit"
        class="cursor-pointer w-auto h-100 mr-1"
        @click="chooseCard"
      >
      <!--      <strong-->
      <!--        v-if="!$device.mobile"-->
      <!--        class="font-14 text-primary"-->
      <!--      >-->
      <!--        {{ getCreditCardName(card.brand) }}-->
      <!--      </strong>-->
    </b-col>
    <b-col
      cols="6"
      md="3"
      :data-testid="`last-card-4-${index}`"
      class="text-primary pr-0"
      :class="{ 'font-14': $device.mobile }"
    >
      {{ cardNumberHandler }}
    </b-col>
    <b-col
      v-if="hasActiveButton"
      cols="12"
      md="6"
    >
      <div class="d-flex align-items-center justify-content-between">
        <b-badge
          id="status-card"
          data-testid="status-card"
          pill
          :variant="card.verifiedAt ? 'light-success' : 'light-warning'"
          class="font-14 px-1"
        >
          {{ card.verifiedAt ? $t('payment.creditCard.statusVerified') : $t('payment.creditCard.statusPendingCard') }}
        </b-badge>
        <b-button
          v-if="!card.verifiedAt && card.default"
          id="action-card"
          data-testid="action-card"
          variant="primary"
          class="font-14 d-flex align-items-center"
          pill
          size="sm"
          @click="initCard"
        >
          {{ $t('payment.textActive') }}
        </b-button>
        <feather-icon
          v-if="canRemoveCard"
          v-b-tooltip.v-light="$t('payment.textRemoveCard')"
          id="remove-card"
          data-testid="remove-card"
          class="cursor-pointer text-primary ml-1"
          icon="Trash2Icon"
          size="16"
          @click.stop="onRemove"
        />
      </div>
    </b-col>
    <b-col
      v-else
      cols="2"
      md="4"
    >
      <feather-icon
        v-if="canRemoveCard"
        v-b-tooltip.v-light="$t('payment.textRemoveCard')"
        :data-testid="`icon-remove-card-${index}`"
        class="cursor-pointer text-primary"
        icon="Trash2Icon"
        size="16"
        @click.stop="onRemove"
      />
    </b-col>
  </b-row>
</template>
<script>
import {
  BIconRecordCircle,
  BIconCircle,
  VBModal,
  BRow,
  BCol,
  VBTooltip,
  BBadge,
  BButton,
} from 'bootstrap-vue'
// mixins
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import billingMixin from '@/mixins/billingMixin'

import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('billing')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BIconRecordCircle,
    BIconCircle,
    BBadge,
    BButton,
  },
  mixins: [toastification, paymentMixin, billingMixin],
  props: {
    card: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    hasOnlyOneCard: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      activeConfirm: false,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersAuth(['plansStatus']),

    cardNumberHandler() {
      return this.card && this.card.last4
        ? `**** ${this.card.last4}`
        : '**** ****'
    },

    hasActiveButton() {
      return window.location.pathname !== '/pricing' && window.location.pathname !== '/reactive-stripe'
    },

    canRemoveCard() {
      if (this.isActive) {
        return !!(
          this.noPlan
          || this.isWhiteAgency
          || this.isBlackAgency
          || (this.isTrialing && this.hasOnlyOneCard)
        )
      }
      return true
    },
  },

  methods: {
    chooseCard() {
      this.$emit('chooseCard', this.card)
    },

    onRemove() {
      if (this.isActive && this.isTrialing) {
        this.showDialogForTrial()
      } else {
        this.showDialog()
      }
    },

    showDialog() {
      this.$bvModal
        .msgBoxConfirm(this.$t('payment.creditCard.contentConfirmRemoveCard'), {
          title: this.$t('payment.creditCard.titleModalConfirmRemoveCard'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.btnYes'),
          cancelTitle: this.$t('common.btnNo'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          const id = this.card.paymentMethodId
          if (value) {
            await this.$emit('deleteCard', id)
          }
        })
    },

    showDialogForTrial() {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('payment.creditCard.contentModalConfirmRemoveCardWillEndTrial'),
          {
            title: this.$t('payment.creditCard.titleModalConfirmRemoveCard'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('common.btnYes'),
            cancelTitle: this.$t('common.btnNo'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async value => {
          const id = this.card.paymentMethodId
          if (value) {
            await this.$emit('cancelTrial', id)
          }
        })
    },

    initCard() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else {
        this.$emit('openActiveCardModal')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
.card-item {
  padding: 0.4rem 0 1.4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  &:last-child {
    padding-bottom: 0.4rem;
  }

  .icon-color{
    color: $green;
  }
}
</style>
