<template>
  <div class="mb-2 summary-container">
    <h3>{{ $t('addFundPage.creditCardPayment.textSummary') }}</h3>
    <p>
      {{ $t('pricingPage.purchaseSuccessView.textPaymentInformationDetails') }}
    </p>
    <hr class="hr-custom">
    <b-spinner
      v-if="getLoading"
      variant="secondary"
      small
    />
    <div v-else>
      <div v-if="upcomingPrice">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="font-14 text-placeholder">
            {{ $t('pricingPage.purchaseSuccessView.textPlanPackage') }}:
          </div>
          <strong data-testid="plan-name">
            {{ name }}
          </strong>
        </div>
        <div
          data-testid="text-price-plan"
          class="d-flex align-items-center justify-content-between mb-1"
        >
          <div class="font-14 text-placeholder">
            {{ $t('pricingPage.purchaseSuccessView.textPrice') }}:
          </div>
          {{ basePrice }}
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="font-14 text-placeholder">
            {{ $t('pricingPage.purchaseSuccessView.textPlanCycle') }}:
          </div>
          <div>
            {{ cycleTimeTxt }}
          </div>
        </div>
      </div>
      <hr class="hr-custom">
      <div v-if="currentPrice && upcomingPrice">
        <div>
          <div class="d-flex align-items-center justify-content-between mb-1 font-medium">
            {{ descriptionUpcomingPrice }}
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-14 text-placeholder">
              {{ $t('balance.textAmount') }}:
            </div>
            <div data-testid="amount-up-coming-price">
              {{ amountUpcomingPrice }}
            </div>
          </div>
        </div>
        <hr class="hr-custom">
        <div>
          <div class="d-flex align-items-center justify-content-between mb-1 font-medium">
            {{ descriptionCurrentPrice }}
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-14 text-placeholder">
              {{ $t('balance.textAmount') }}:
            </div>
            <div data-testid="amount-current-price">
              {{ amountCurrentPrice }}
            </div>
          </div>
        </div>
        <hr class="hr-custom">
      </div>
      <div class="d-flex align-items-center justify-content-between my-1">
        <div class="font-14 text-placeholder">
          {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}:
        </div>
        <div class="d-flex align-items-center">
          <h3
            data-testid="total-price-plan"
            class="font-medium"
          >
            {{ total }}
          </h3>
          /
          <span class="font-14 font-weight-normal">{{ cycleTimeTxt }}</span>
        </div>
      </div>
      <btn-loading
        data-testid="btn-start-plan"
        class="mb-2"
        type="submit"
        variant-convert="btn-submit"
        block
        pill
        :loading="starPlanLoading"
        :disabled="checkDisable"
        @click="checkBeforeUpgrade"
      >
        {{ btnTxt }}
      </btn-loading>

      <div
        v-if="errorMessagePlan"
        class="error-message-stripe"
      >
        <error-tag :error="errorMessagePlan || ''" />
      </div>

      <div class="brand-logo d-flex justify-content-between">
        <div class="d-flex">
          <img
            v-for="(url, i) in brandLogo"
            :key="i"
            :src="url"
            alt="brand"
            style="margin-right: 3px"
            :style="{'width': i === brandLogo.length - 1 ? '51px' : '26px'}"
          >
        </div>
        <span class="font-14">{{ $t('payment.textGuaranteed') }} <span class="font-medium text-success">{{ $t('payment.textSafe') }}</span> {{ $t('payment.textCheckout') }}</span>
      </div>
      <div class="notes">
        <div v-if="isTrialing">
          <img
            alt="warning"
            style="margin-right: 5px"
            :src="require('@/assets/images/common/ic-warning.svg')"
          >
          <span
            class="font-14"
          >{{ $t('pricingPage.textBeforeTrialEnd') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import billingMixin from '@/mixins/billingMixin'
import { toastification } from '@core/mixins/toast'
import axios from '@axios'
import { getResponse } from '@/plugins/store'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import _get from 'lodash/get'
import errorTag from '@/components/errorTag.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const {
  mapGetters: mapGettersAuth,
  mapActions: mapActionsAuth,
} = createNamespacedHelpers('auth')
const {
  mapGetters: mapGettersBilling,
  mapActions: mapActionsBilling,
} = createNamespacedHelpers('billing')

export default {
  components: {
    errorTag,
    BSpinner,
    BtnLoading,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [toastification, numberFormatMixin, billingMixin],
  props: {
    checkoutData: {
      type: Object,
      required: true,
      default: () => {},
    },

    isMonthly: {
      type: Boolean,
      required: true,
      default: true,
    },

    isResetError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      starPlanLoading: false,
      errorMessagePlan: '',
      // brand logo
      brandLogo: [
        require('@/assets/images/pages/payment/ic-visacard.svg'),
        require('@/assets/images/pages/payment/ic-mastercard.svg'),
        require('@/assets/images/pages/payment/ic-amexcard.svg'),
        require('@/assets/images/pages/payment/ic-discovercard.svg'),
      ],
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading']),
    ...mapGettersAuth(['user']),
    ...mapGettersBilling(['pricing', 'loading', 'error', 'subscriptionStatus']),

    getLoading() {
      return _get(this.checkoutData, ['getInvoiceLoading'])
    },

    total() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'total'], 0) / 100)
    },

    currentPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'currentPrice'], null)
    },

    amountCurrentPrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'currentPrice', 'amount'], 0) / 100)
    },

    descriptionCurrentPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'currentPrice', 'description'], null)
    },

    upcomingPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice'], null)
    },

    amountUpcomingPrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'amount'], 0) / 100)
    },

    descriptionUpcomingPrice() {
      return _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'description'], null)
    },

    cycleTimeTxt() {
      return this.isMonthly ? this.$t('partnerView.textMonthly') : this.$t('partnerView.textYearly')
    },

    basePrice() {
      return this.currencyFormat(_get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'plan', 'amount'], 0) / 100)
    },

    name() {
      const nickName = _get(this.checkoutData, ['stripeInvoice', 'upcomingPrice', 'plan', 'nickname'])
      return nickName
    },

    btnTxt() {
      return _get(this.checkoutData, ['isContinuePurchase'], false) || this.hasPreviousPlan ? this.$t('pricingPage.textContinuePurchase') : this.$t('pricingPage.textStartPlan')
    },

    checkDisable() {
      const amount = this.checkoutData?.planData?.amount
      return (this.listCard?.length === 0) && amount !== 0
    },

  },

  watch: {
    isResetError: {
      handler(isReset) {
        if (isReset) {
          this.errorMessagePlan = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {

    ...mapActionsBilling(['getPricing', 'getSubscriptionStatus']),
    ...mapActionsAuth(['getAccountInfo']),

    async checkBeforeUpgrade() {
      this.$emit('reset-error')
      this.errorMessagePlan = ''
      if (this.checkoutData?.isContinuePurchase || this.hasPreviousPlan) {
        this.reConfirmSubscription()
      } else {
        this.upgradeSubscription()
      }
    },

    async reConfirmSubscription() {
      await this.getSubscriptionStatus()
      const {
        clientSecret,
        paymentMethod,
      } = this.latestInvoice
      if (clientSecret && paymentMethod) {
        this.starPlanLoading = true
        try {
          const result = await this.$stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: paymentMethod,
            },
          )
          if (result.error) {
            // this.toastFailure(result.error.message || 'Upgrade failure!')
            this.errorMessagePlan = result.error.message || 'Upgrade failure!'
            this.starPlanLoading = false
          } else {
            const stt = result.paymentIntent.status

            if (stt === 'succeeded') {
              await axios.post('/api/stripe-payment/subscriptions/fulfill')
              this.actionAfterUpgradeSuccess()
            }
          }
        } catch (e) {
          // this.toastFailure('Upgrade failure!')
          this.errorMessagePlan = 'Upgrade failure!'
          this.starPlanLoading = false
        }
      } else {
        // this.toastFailure('Upgrade failure!')
        this.errorMessagePlan = 'Upgrade failure!'
        this.starPlanLoading = false
      }
    },

    async upgradeSubscription() {
      try {
        this.starPlanLoading = true
        const { data } = await axios.post('/api/stripe-payment/subscriptions/register', {
          priceIds: [this.checkoutData?.planData?.id],
        })

        const { status } = getResponse(data)

        if (status === 'active' || status === 'trialing') {
          this.actionAfterUpgradeSuccess()
        } else if (status === 'incomplete') {
          const paymentIntentStatus = getResponse(data).latestInvoice.paymentIntent.status

          if (paymentIntentStatus === 'requires_confirmation' || paymentIntentStatus === 'requires_action') {
            this.confirmPayment(this.checkoutData?.planData, getResponse(data).latestInvoice.paymentIntent.clientSecret, getResponse(data).latestInvoice.paymentIntent.paymentMethod)
          } else {
            this.starPlanLoading = false
            // this.toastFailure('Upgrade failure!')
            this.errorMessagePlan = 'Upgrade failure!'
          }
        } else if (status === 'past_due') {
          this.starPlanLoading = false
          // this.toastFailure('Your card has insufficient funds.')
          this.errorMessagePlan = this.$t('pricingPage.cardInsufficientFunds')
        }
      } catch (e) {
        this.starPlanLoading = false
        // await this.toastFailure(e.data.message)
        this.errorMessagePlan = e.data.message
      }
    },

    async confirmPayment(plan, clientSecret, defaultPaymentMethod) {
      try {
        const result = await this.$stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: defaultPaymentMethod,
          },
        )
        if (result.error) {
          // this.toastFailure(result.error.message || 'Confirm payment failure!')
          this.errorMessagePlan = result.error.message || 'Confirm payment failure!'
          this.starPlanLoading = false
          this.getSubscriptionStatus() // show alert if user confirm failure
        } else {
          const stt = result.paymentIntent.status

          if (stt === 'succeeded') {
            await axios.post('/api/stripe-payment/subscriptions/fulfill')
            this.actionAfterUpgradeSuccess()
          }
        }
      } catch (e) {
        this.starPlanLoading = false
        // this.toastFailure('Server error!')
        this.errorMessagePlan = 'Server error!'
      }
    },

    actionAfterUpgradeSuccess() {
      this.starPlanLoading = false
      this.getAccountInfo()
      this.getSubscriptionStatus()
      this.$emit('show-success-page')
    },
  },
}
</script>
<style lang="scss" scoped>
.summary-container {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px;
}

.hr-custom {
  border-top: 1px solid #EEEEEE;
}

.error-message-stripe {
  margin: 0 0 24px 0;
}
</style>
